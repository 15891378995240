export enum generalReceiptEnum {
  pay = "pay",
  collect = "collect",
}

export const generalReceiptTypes = {
  collect: {
    name: "collect",
    displayName: "Collection Receipt",
    displayNameTranslations: {
      ar: "سند قبض",
    },
    enum: generalReceiptEnum.collect,
  },
  pay: {
    name: "pay",
    displayName: "Payment Receipt",
    displayNameTranslations: {
      ar: "سند صرف",
    },
    enum: generalReceiptEnum.pay,
  },
};

export type GeneralReceiptType = keyof typeof generalReceiptTypes;
